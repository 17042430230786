
<template>
  <div class="myMenu" id="myMenu">
    <ul>
      <li
        v-for="(item, index) in val"
        :key="item.id"
        :class="activeClass == item.id ? 'active' : (index == 0 && activeClass == 0 ? 'active' : '')"
        @click="mouseClick($event,item.id)"
      >
        <router-link to>
          <!-- <slot :name="item"></slot> -->
			{{item.name}}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['val'],
  name: 'MyMenu',
  data() {
    return {
      activeClass: 0
    }
  },
  methods: {
    // 通过mouseover事件控制当前显示的商品分类，1为该类别的热门商品
    mouseClick(e, val) {
      this.activeClass = val
    }
  },
  watch: {
    // 向父组件传过去当前要显示的商品分类，从而更新商品列表
    activeClass: function(val) {
      this.$emit('fromChild', val)
    }
  }
}
</script>
<style scoped>
#myMenu{
	padding: 13px 0;
	max-width: 960px;
}
#myMenu:after {
	display: block;
	content: '';
	clear: both;
}
#myMenu li {
  float: left;
  margin-left: 30px;
}
#myMenu li a{
	line-height: 32px;
}
#myMenu a:hover {
  color: #ff6700;
  border-bottom: 2px solid #ff6700;
}

#myMenu .active a {
  color: #ff6700;
  border-bottom: 2px solid #ff6700;
}
</style>