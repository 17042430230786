/*
 * @Descripttion:调用后端购物车接口的函数
 * @Author: congz
 * @Date: 2020-06-14 15:50:08
 * @LastEditors: congz
 * @LastEditTime: 2020-08-05 15:33:58
 */

import axios from 'axios'
import request from '@/utils/request.js'

// 创建购物车
// const postCart = form => axios.post('/api/v1/carts', form).then(res => res.data)
const postCart = form => request({
	url: '/user/cart',
	method: 'post',
	data: form
})

// // 读取购物车
// const showCarts = user_id =>
//   axios.get(`/api/v1/carts/${user_id}`).then(res => res.data)
const showCarts = query => request({
	url: '/user/cart',
	method: 'get',
	params: query ?? {}
})

// 更新购物车
// const updateCart = form =>
//   axios.put('/api/v1/carts', form).then(res => res.data)
const updateCart = (id, params) => request({
	url: '/user/cart/' + id,
	method: 'put',
	data: params
})

// 删除购物车
// const deleteCart = form =>
//   axios.delete('/api/v1/carts', { data: form }).then(res => res.data)
const deleteCart = id => request({
	url: '/user/cart/' + id,
	method: 'delete'
})

export { postCart, showCarts, updateCart, deleteCart }
