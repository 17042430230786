import request from '@/utils/request.js'

export default {
    getCategoryList: (query) => {
        return request({
            url: '/user/product/category',
            method: 'get',
            params: query ?? {}
        })
    }
}
