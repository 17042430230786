/*
 * @Descripttion:商品分类模块
 * @Author: zhu
 * @Date: 2024-07-03
 */

export default {
	state: {
		categoryList: []
	},
	getters: {
		getCategory(state) {
			return state.categoryList
		}
	},
	mutations: {
		setCategory(state, data) {
			state.categoryList = data
		}
	},
	actions: {
		setCategory({ commit }, data) {
			commit('setCategory', data)
		}
	}
}
