/*
 * @Descripttion:网站参数
 * @Author: zhu
 * @Date: 2024-08-14
 */

export default {
	state: {
		configList: []
	},
	getters: {
		getConfig(state) {
			return state.configList
		}
	},
	mutations: {
		setConfig(state, data) {
			state.configList = data
		}
	},
	actions: {
		setConfig({ commit }, data) {
			commit('setConfig', data)
		}
	}
}
