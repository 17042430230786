import Cookies from 'js-cookie'

const TokenKey = 'User-Token'

export function getToken() {
    return Cookies.getJSON(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getTokenHeaders() {
    const token = Cookies.getJSON(TokenKey)
    const tokenHeaders = {}
    if (token) {
        tokenHeaders.Authorization = token.token_type + ' ' + token.access_token
    }

    return tokenHeaders
}
