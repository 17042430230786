/*
 * @Descripttion:Vuex入口
 * @Author: congz
 * @Date: 2020-06-04 11:22:40
 * @LastEditors: congz
 * @LastEditTime: 2020-07-17 10:44:04
 */

import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import shoppingCart from './modules/shoppingCart'
import category from './modules/category'
import websiteConfig from './modules/websiteConfig'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    user,
    shoppingCart,
	category,
	websiteConfig
  }
})
