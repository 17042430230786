<!--
 * @Descripttion: 项目根组件
 * @Author: congz
 * @Date: 2020-06-04 11:22:40
 * @LastEditors: congz
 * @LastEditTime: 2020-08-18 23:06:46
--> 

<template>
  <div id="app" name="app" @mouseover="hidePannel">
	<head>
		<meta name="keywords" :content="web_keywords">
		<meta name="description" :content="web_description">
		<link rel="icon" :href="web_favicon" />
	</head>
    <el-container>
      <!-- 顶部导航栏 -->
      <div class="topbar" v-show="$route.meta.showMenu!==false">
        <div class="nav">
          <ul>
            <li v-if="!this.$store.getters.getUser">
              <div style="margin-top:5px;font-size:16px">
                <router-link to="/login">登录</router-link>
              </div>
            </li>
            <li v-else class="header-user-con">
              <!-- 用户头像 -->
              <div class="user-avator">
                <img :src="this.avatar" />
              </div>
              <!-- 用户名下拉菜单 -->
              <div class="user-name">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    {{this.$store.getters.getUser.nickname}}
                    <i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                   <!-- <a href="https://github.com/congz666/cmall-go" target="_blank">
                      <el-dropdown-item>项目仓库</el-dropdown-item>
                    </a> -->
                    <router-link to="/user/details">
                      <el-dropdown-item>个人信息</el-dropdown-item>
                    </router-link>
					<router-link to="/user/address">
						<el-dropdown-item>收货地址</el-dropdown-item>
					</router-link>
                    <a @click="logout">
                      <el-dropdown-item>退出登录</el-dropdown-item>
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </li>
            <!-- <li class="font">
              <router-link to="/favorite">我的收藏</router-link>
            </li> -->
            <li class="font">
              <router-link to="/order">我的订单</router-link>
            </li>

            <li :class="getNum > 0 ? 'shopCart-full' : 'shopCart'">
              <div style="margin-top:5px">
                <router-link to="/cart">
                  <i class="el-icon-shopping-cart-full"></i> 购物车
                  <span>({{getNum}})</span>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 顶部导航栏END -->

      <!-- 顶栏容器 -->
		<div class="top-search" v-show="$route.meta.showMenu!==false">
			<div class="logo">
				<router-link to="/">
					<img src="./assets/imgs/clogo.png" alt />
				</router-link>
			</div>
			<div class="so">
				<el-input placeholder="请输入 商品名/品类/品牌 搜索" v-model="search" @keyup.native.enter="searchClick" clearable>
					<el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
				</el-input>
			</div>
		</div>
      <el-header v-show="$route.meta.showMenu!==false">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          active-text-color="#ff6700"
          background-color="#ffffff"
          router
        >
			<div class="main-menu cate-menu">
				<div class="menu-til" @mouseover.stop="catMenuShow2 = true">
					<i class="icon el-icon-s-unfold"></i>
					<div class="text">商品分类</div>
				</div>
				<div class="menu" v-show="catMenuShow || catMenuShow2" :style="{top: catMenuShow2 && !catMenuShow ? '36px' : '38px'}" @mouseover.stop>
					<div class="menu-bg"></div>
					<div class="menu-con">
						<div v-for="(item, index) in cateArr" :class="'hui-menu-item ' + itemHoverClassArr[index]" @mouseover.stop="activeItem(index)" :key="index">
							<router-link :to="{ path: '/goods', query: {categoryID:item.id} }">{{ item.name }}</router-link>
						</div>
					</div>
					<div v-if="pannelDisplay" class="pannel" @mouseover.stop>
						<div v-for="(item, index) in pannelArr" v-show="pannelDisplayArr[index]" class="pannel-item" @mouseover.stop="activeItem(index)" :key="index">
							<div v-for="(it0, ind0) in item" :key="ind0" style="margin-bottom: 14px;">
								<div class="pannel-item-head"><router-link :to="{ path: '/goods', query: {categoryID:it0.id} }">{{ it0.name }}</router-link></div>
								<div class="pannel-item-con clearfix">
									<router-link v-for="(it, ind) in it0.children" :to="{ path: '/goods', query: {categoryID:it.id} }" :key="ind">{{ it.name }}</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-menu-item index="/">首页</el-menu-item>
			<el-menu-item index="/goods">全部商品</el-menu-item>
			<!-- <el-menu-item index="/about">关于我们</el-menu-item> -->
			<el-menu-item v-for="(item, index) in moduleNav" :index="item.is_imgtext === 'y' ? '/article?id=' + item.cms_id : '/cms/list?tags=' + item.tag" :key="index">{{ item.label }}</el-menu-item>
			<!-- <div class="so">
				<el-input placeholder="请输入搜索内容" v-model="search" @keyup.native.enter="searchClick" clearable>
					<el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
				</el-input>
			</div> -->
        </el-menu>
      </el-header>

      <!-- 顶栏容器END -->

      <!-- 主要区域容器 -->
      <el-main>
		<div ref="mainBox" :style="{'min-height': mainMinHeight + 'px'}">
			<keep-alive>
				<router-view @changeSearchStr="changeSearch" :cartLoading="cartLoading" :key="key"></router-view>
			</keep-alive>
		</div>
      </el-main>
      <!-- 主要区域容器END -->

      <!-- 底栏容器 -->
      <el-footer>
        <div class="footer" ref="footerBox">
          <!-- <div class="ng-promise-box">
            <div class="ng-promise">
              <p class="text">
                <a class="icon1" href="javascript:;">7天无理由退换货</a>
                <a class="icon2" href="javascript:;">满99元全场免邮</a>
                <a class="icon3" style="margin-right: 0" href="javascript:;">100%品质保证</a>
              </p>
            </div>
          </div> -->
          <div class="mod_help">
            <p>
              <router-link to="/">首页</router-link>
              <span>|</span>
              <router-link to="/goods">全部商品</router-link>
              <span>|</span>
              <router-link to="/about">关于我们</router-link>
            </p>
            <p>Copyright ©2024, shangxuan, <a href="http://beian.miit.gov.cn/" target="_blank">{{ beianhao }}</a></p>
          </div>
        </div>
      </el-footer>
      <!-- 底栏容器END -->
    </el-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import * as cartsAPI from '@/api/carts'
import categoryAPI from '@/api/category.js'
// import * as userAPI from '@/api/users'
import configAPI from '@/api/website-config.js'
import { removeToken } from '@/utils/auth.js'
import { handleAvatar } from '@/utils/index.js'

export default {
  beforeUpdate() {
    this.activeIndex = this.$route.path
  },
  data() {
    return {
		activeIndex: '', // 头部导航栏选中的标签
		search: '', // 搜索条件
		catMenuShow: false, // catMenuShow与catMenuShow2有一个为true则显示分类界面
		catMenuShow2: false,
		cateArr: [],
		pannelArr: [],
		itemHoverClassArr: [],
		pannelDisplay: false,
		pannelDisplayArr: [],
		screenHeight: 0,
		footerHeight: 0,
		orgMainTop: 0,
		mainTop: 0,
		cartLoading: true,
		avatar: '',
		moduleNav: [],
		beianhao: '粤ICP备11025010号-3',
		web_keywords: '尚选商城，文具，办工文具，办公耗材，办公电器',
		web_description: '尚选商城，文具，办工文具，办公耗材，办公电器',
		web_favicon: 'favicon.ico'
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#f5f5f5')
  },
  created() {
    // 获取浏览器localStorage，判断用户是否已经登录
   //  userAPI.checkToken(localStorage.getItem('token')).then(res => {
   //    // 如果已经登录，设置vuex登录状态
   //    if (res.status_code == 200 && res.data) {
			// this.setUser(JSON.parse(localStorage.getItem('user')))
   //    } else {
   //      localStorage.removeItem('user')
   //      localStorage.removeItem('token')
   //    }
   //  })
	// 获取商品分类
	// categoryAPI.getCategoryList({ page: 1, limit: 14 }).then(res => {
	// 	if (res.status_code == 200) {
	// 		const cate = res.data.data
	// 		this.setCategory(cate)
	// 	}
	// }).catch(err => {
	// })
  },
  mounted() {
	this.screenHeight = window.innerHeight // 获取屏幕高度
	// 监听窗口大小变化，更新屏幕高度
	window.addEventListener('resize', this.handleResize)
	this.footerHeight = this.$refs.footerBox.getBoundingClientRect().height
	this.mainTop = this.$refs.mainBox.getBoundingClientRect().top
	this.orgMainTop = this.$refs.mainBox.getBoundingClientRect().top
  },
  beforeDestroy() {
	// 清除监听器
	window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapGetters(['getUser', 'getNum', 'getCategory', 'getConfig']),
    key() {
      return this.$route.path + Math.random()
    },
	mainMinHeight() {
		const minHeight = this.screenHeight - this.mainTop - this.footerHeight
		return minHeight > 200 ? minHeight : 200
	}
  },
  watch: {
    // 获取vuex的登录状态
    getUser: function(val) {
      if (val === '') {
        // 用户没有登录
        this.setShoppingCart([])
      } else {
		// 获取网站配置参数:title、备案号、description等
		configAPI.getConfig({ tags: 'website' }).then(res => {
			if (res.status_code == 200) {
				this.setConfig(res.data)
			}
		}).catch(err => {
		})
		// 获取在首页导航栏显示的模型
		configAPI.getShowModule({ page: 1, limit: 8, is_show_nav: 'y' }).then(res => {
			if (res.status_code == 200) {
				this.moduleNav = res.data.data
			}
		}).catch(err => {
		})
        // 用户已经登录,获取该用户的购物车信息
        this.cartLoading = true
		cartsAPI
          .showCarts()
          .then(res => {
            if (res.status_code === 200) {
              if (res.data === null) {
                this.setShoppingCart([])
              } else {
				for(let i = 0; i < res.data.length; i++){
					res.data[i].check = false
				}
                this.setShoppingCart(res.data)
              }
            }
			// else if (res.status === 20001) {
   //            //token过期，需要重新登录
   //            this.loginExpired(res.msg)
   //          } 
			else {
              this.$notify.error({
                title: '购物车获取失败',
                message: res.msg
              })
            }
			this.cartLoading = false
          })
          .catch(err => {
            return Promise.reject(err)
          })
		// 获取产品分类数据
		categoryAPI.getCategoryList({ page: 1, limit: 100 }).then(res => {
			if (res.status_code == 200) {
				const cate = res.data.data
				this.setCategory(cate)
			}
		}).catch(err => {
		})
		// this.$store.getters.getUser.avatar
		this.avatar = handleAvatar(val.avatar)
      }
    },
	$route: function(val) {
		if (val.name === 'Home') { // 首页显示分类框
			this.catMenuShow = true
		} else {
			this.catMenuShow = false
		}
		if (val.meta.showMenu === false) {
			this.mainTop = 0
		} else {
			this.mainTop = this.orgMainTop
		}
	},
	getCategory: function(val) {
		this.cateArr = []
		this.pannelArr = []
		this.itemHoverClassArr = []
		for (let i = 0; i < val.length; i++) {
			if (val[i].id > 0) {
				this.itemHoverClassArr.push('')
				this.pannelDisplayArr.push(false)
				this.cateArr.push({ id: val[i].id, name: val[i].name })
				let pannelOne = []
				if (val[i].children && val[i].children.length > 0) {
					for (let j = 0; j < val[i].children.length; j++) {
						const children = val[i].children[j].children && val[i].children[j].children.length > 0 ? val[i].children[j].children : []
						pannelOne.push({id: val[i].children[j].id, name: val[i].children[j].name, children: children })
					}
				}
				this.pannelArr.push(pannelOne)
			}
		}
	},
	getConfig: function(val) {
		val.map(item => {
			if (item.key === 'website_title' && item.value) {
				window.document.title = item.value
			}
			if (item.key === 'website_beian' && item.value) {
				this.beianhao = item.value
			}
			if (item.key === 'website_keywords' && item.value) {
				this.web_keywords = item.value
			}
			if (item.key === 'website_description' && item.value) {
				this.web_description = item.value
			}
			if (item.key === 'website_favicon' && item.value) {
				this.web_favicon = item.value
			}
		})
	}
  },
  methods: {
    ...mapActions(['setUser', 'setShoppingCart', 'setCategory', 'setConfig']),
	changeSearch(val) {
		this.search = val
	},
	handleResize() {
		this.screenHeight = window.innerHeight
	},
	activeItem(index) {
		this.pannelDisplay = true
		for (let i = 0; i < this.itemHoverClassArr.length; i++) {
			if (i === index) {
				this.$set(this.itemHoverClassArr, i, 'hover')
				this.$set(this.pannelDisplayArr, i, true)
			} else {
				this.$set(this.itemHoverClassArr, i, '')
				this.$set(this.pannelDisplayArr, i, false)
			}
		}
	},
	hidePannel() {
		this.pannelDisplay = false
		this.catMenuShow2 = false
		for (let i = 0; i < this.itemHoverClassArr.length; i++) {
			this.$set(this.itemHoverClassArr, i, '')
			this.$set(this.pannelDisplayArr, i, false)
		}
	},
    login() {
      this.$router.push({
        name: 'Login'
      })
    },
    // 退出登录
    logout() {
      // 清空本地登录信息
      localStorage.removeItem('user')
      localStorage.removeItem('token')
		removeToken()
      // 清空vuex登录信息
      this.setUser('')
      this.$router.push({
        name: 'Login'
      })
      this.notifySucceed('登出成功')
    },
    //重定向
    register() {
      this.$router.push({
        name: 'Register'
      })
    },
    // 点击搜索按钮
    searchClick() {
      // if (this.search != '') {
        // 跳转到全部商品页面,并传递搜索条件
        this.$router.push({ path: '/goods', query: { search: this.search } })
        this.search = ''
      // }
    }
  }
}
</script>

<style>
/* 全局CSS */
* {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}

.header-user-con {
  display: flex;
  height: 50px;
  align-items: center;
}
.user-name {
  margin-left: 10px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: center;
}
.el-dropdown-item:hover {
  color: #ff6700;
}

#app .el-header {
  padding: 0;
}
#app .el-main {
  min-height: 300px;
  padding: 0;
}
#app .el-footer {
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
/* 全局CSS END */

/* 顶部导航栏CSS */
.topbar {
  height: 50px;
  background-color: #303643;
}
.topbar .nav {
	width: 100%;
	max-width: 1225px;
	margin: 0 auto;
}
.topbar .nav ul {
  float: right;
}
.topbar .nav li {
  float: left;
  height: 50px;
  color: #b0b0b0;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  margin-left: 20px;
}
.topbar .nav li.font {
  margin-top: 5px;
}

.topbar .nav li .router-link {
  margin-top: 5px;
}

.topbar .nav li .el-button {
  color: #b0b0b0;
}
.topbar .nav .el-button:hover {
  color: #fff;
}
.topbar .nav li a {
  color: #b0b0b0;
}
.topbar .nav a:hover {
  color: #fff;
}
.topbar .nav .shopCart {
  width: 120px;
  background: #424242;
}
.topbar .nav .shopCart:hover {
  background: #fff;
}
.topbar .nav .shopCart:hover a {
  color: #ff6700;
}
.topbar .nav .shopCart-full {
  width: 120px;
  background: #ff6700;
}
.topbar .nav .shopCart-full a {
  color: white;
}

/* 顶部导航栏CSS END */

/* 顶栏容器CSS */
.top-search {
	width: 100%;
	max-width: 1225px;
	margin: 25px auto;
	align-items: center;
	height: 40px;
}
.top-search .so {
  width: 400px;
  float: right;
}
.top-search .logo {
	height: 60px;
    width: 100px;
    float: left;
}
.top-search .logo img {
  height: 60px;
  margin-top: -10px;
}

/*  分类菜单 start */
.main-menu {
    width: 196px;
    height: 36px;
    float: left;
    position: relative;
}
.main-menu .menu-til {
    height: 36px;
    line-height: 36px;
    background: #ff6700;
}
.main-menu .menu-til .text {
    float: left;
	margin-left: 14px;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	cursor: default;
}
.main-menu .menu-til .icon {
    float: left;
	margin-top: 9px;
    margin-left: 15px;
    margin-right: 0;
    transform: none;
	color: white;
	font-size: 18px;
}
.main-menu .menu {
    width: 196px;
    height: 460px;
    position: absolute;
    left: 0;
    top: 38px;
    /* overflow: hidden; */
	z-index: 100;
}
.main-menu .menu .menu-bg {
    width: 196px;
    height: 100%;
    background: #fff;
    filter: alpha(opacity:90);
    opacity: 0.9;
}
.main-menu .menu .menu-con {
    width: 196px;
    position: absolute;
    left: 0;
    top: 0;
	height: 100%;
	overflow: hidden;
}
.cate-menu .menu .menu-con .hui-menu-item {
    height: 28.5px;
    line-height: 28.5px;
    padding-left: 10px;
    font-size: 14px;
    overflow: hidden;
	width: 196px;
	box-sizing: border-box;
	position: relative;
	z-index: 2;
}
.cate-menu .menu .menu-con .hui-menu-item a {
    color: #030000;
}
.cate-menu .menu .menu-con .hui-menu-item.hover {
    background: #fff;
}
.cate-menu .menu .menu-con .hui-menu-item.hover a {
    color: #ff6700;
}
.main-menu .menu .menu-con .icon {
    display: inline-block;
    width: 16px;
    height: 100%;
    margin-right: 8px;
    vertical-align: top;
	float: left;
}
.main-menu .menu .pannel {
    width: 828px;
	height: 460px;
    background: #fff;
    position: absolute;
    left: 196px;
    top: 0;
	z-index: 11;
	overflow-y: scroll;
}
.main-menu .menu .pannel .pannel-item {
    padding: 7px 25px;
}
.main-menu .menu .pannel .pannel-item .pannel-item-head {
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    border-bottom: 1px dashed #ececec;
	font-size: 14px;
	font-weight: bold;
}
.main-menu .menu .pannel .pannel-item .pannel-item-head a {
	color: #ff6700;
}
.main-menu .menu .pannel .pannel-item .pannel-item-con {
    margin-top: 5px;
    line-height: 20px;
}
.main-menu .menu .pannel .pannel-item .pannel-item-con a {
    margin-left: 10px;
    white-space: nowrap;
	color: #757575;
	font-size: 12px;
}
.main-menu .menu .pannel .pannel-item .pannel-item-con a:hover {
	color: #ff6700;
	text-decoration: underline;
}
.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    line-height: 0;
    font-size: 0;
}
/*  分类菜单 end */

.el-menu.el-menu--horizontal {
	border-bottom: none !important;
}
.el-menu--horizontal>.el-menu-item {
	height: 36px !important;
	line-height: 36px !important;
	border-bottom: none !important;
}
.el-header {
  background-color: #ffffff;
  border-bottom: solid 2px #ff6700;
  height: 38px !important;
}
.el-header .el-menu {
  max-width: 1225px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
/* 顶栏容器CSS END */

/* 底栏容器CSS */
.footer {
  width: 100%;
  text-align: center;
  background: #2f2f2f;
  padding: 16px 0;
}
.footer .ng-promise-box {
  border-bottom: 1px solid #3d3d3d;
  line-height: 145px;
}
.footer .ng-promise-box {
  margin: 0 auto;
  border-bottom: 1px solid #3d3d3d;
  line-height: 145px;
}
.footer .ng-promise-box .ng-promise p a {
  color: #fff;
  font-size: 20px;
  margin-right: 210px;
  padding-left: 44px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-decoration: none;
  background: url('./assets/imgs/us-icon.png') no-repeat left 0;
}
.footer .github {
  height: 50px;
  line-height: 50px;
  margin-top: 20px;
}
.footer .github .github-but {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background: url('./assets/imgs/github.png') no-repeat;
}
.footer .mod_help {
  text-align: center;
  color: #888888;
}
.footer .mod_help p {
  margin: 16px 0 12px 0;
}

.footer .mod_help p a {
  color: #888888;
  text-decoration: none;
}
.footer .mod_help p a:hover {
  color: #fff;
}
.footer .mod_help p span {
  padding: 0 22px;
}
.footer .mod_help a {
  color: #888888;
}
/* 底栏容器CSS END */
</style>