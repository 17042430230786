import request from '@/utils/request.js'

export default {
    getConfig: (query) => { // 获取网站系统参数配置信息
        return request({
            url: '/user/system/get_config',
            method: 'get',
            params: query ?? {}
        })
    },
	getShowModule: (query) => { // 获取在首页显示的模型
		return request({
			url: '/cms/modules',
			method: 'get',
			params: query ?? {}
		})
	}
}
