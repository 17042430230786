import axios from 'axios'
// import router from '@/router'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth.js'
import { getKey } from '@/utils/index.js'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['x-api-key'] = getKey()
        // do something before request is sent
        // 请求的时候带上Token·
        const token = getToken()
        if (token) {
            config.headers['Authorization'] = token.token_type + ' ' + token.access_token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// async function reloadMenu() {
//     const { roles } = await store.dispatch('user/getInfo')
//     const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
//     router.addRoutes(accessRoutes)
// }

// response interceptor
service.interceptors.response.use(
    response => {
        // 无感刷新 Token
        if (response.headers.authorkey && response.headers.authorkey !== undefined) {
            store.dispatch('user/refresh', response.headers.authorkey)
        }

        const res = response.data
        // 返回自定义代码
        // 正确返回代码: 200
        if (res.status_code < 200 || res.status_code >= 400) {
            Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(res)
        } else {
            if (res.message && res.message.toLowerCase() !== 'success') {
                Message({
                    message: res.message,
                    type: 'success',
                    duration: 5 * 1000
                })
            }
            // 刷新左侧菜单栏目
            if (res.status_code === 30001) {
                // reloadMenu()
            }
            return res
        }
    },
    error => {
        // 如果请求异常(登录超时或黑名单), 则直接返回登录页面
        if (
            (error.response.data.status_code === 401 || error.response.data.status_code === 500) &&
            (
                error.response.data.message.toLowerCase().indexOf('token') >= 0 ||
                error.response.data.message.toLowerCase().indexOf('authorization') >= 0 ||
                error.response.data.message.toLowerCase().indexOf('unauthorized') >= 0
            )
        ) {
            // 登录超时提示
            Message({
                type: 'error',
                message: '登录超时！',
                duration: 5 * 1000
            })
            return Promise.reject(error)
        }
        const errorMsg = error.response.data.message ? error.response.data.message : error.message
        Message({
            message: errorMsg,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
