// import parseTime, formatTime and set to filter
// export { parseTime, formatTime } from '@/utils'
import { numberFormat, getLabel, convertCurrency, convertWeight, convertTonPrice, handleSpecDesc } from '@/utils/index.js'
import NP from 'number-precision'
import Vue from 'vue'

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
    if (time === 1) {
        return time + label
    }
    return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
    const between = Date.now() / 1000 - Number(time)
    if (between < 3600) {
        return pluralize(~~(between / 60), ' minute')
    } else if (between < 86400) {
        return pluralize(~~(between / 3600), ' hour')
    } else {
        return pluralize(~~(between / 86400), ' day')
    }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
    const si = [
        { value: 1E18, symbol: 'E' },
        { value: 1E15, symbol: 'P' },
        { value: 1E12, symbol: 'T' },
        { value: 1E9, symbol: 'G' },
        { value: 1E6, symbol: 'M' },
        { value: 1E3, symbol: 'k' }
    ]
    for (let i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
            return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
        }
    }
    return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * 货币数字格式化显示（带分号，用于显示）
 */
export function filterCurrency(value) {
    return numberFormat(convertCurrency(value, false))
}

/**
 * 货币数字四舍五入后，格式化显示（带分号，用于显示）
 */
export function filterCurrencyRound(value) {
    return numberFormat(convertCurrency(Math.round(value), false))
}

/**
 * 数字格式化显示
 */
export function filterNumber(value) {
    return numberFormat(NP.times(+value, 1))
}

/**
 * 重量换算
 */
export function filterWeight(value, unit = 't') {
    return numberFormat(convertWeight(value, unit, false))
}

/**
 * 拓展字段
 */
export function filterExt(exts, name) {
    if (!(exts instanceof Array)) return ''
    let result = ''
    exts.some(item => {
        if (item.name === name) {
            result = item.content
            return true
        }
    })
    return result
}

/**
 * 下单人字段处理
 */
export function filterUser(user) {
    if (user) {
        return user.name
    } else {
        return ''
    }
}

/**
 * 获取状态、类型等名称
 */
export function filterLabel(value, list) {
    return getLabel(list, value)
}

/**
 * 日期格式，只保留年月日
 */
export function filterDate(value) {
    return Vue.prototype.parseTime(value, '{y}-{m}-{d}')
}

/**
 * 单价转换为每吨价格
 */
export function filterTonPrice(price, weight) {
    return convertTonPrice(price, weight)
}

/**
 * 单价转换为每吨价格
 */
export function filterSpecDesc(attrs) {
    return handleSpecDesc(attrs)
}
