import request from '@/utils/request.js'

export function login(data) {
    return request({
        url: '/admin/auth/login',
        method: 'post',
        data
    })
}

export function getInfo() {
    return request({
        url: '/admin/auth/info',
        method: 'post'
    })
}

export function logout() {
    return request({
        url: '/admin/auth/logout',
        method: 'post'
    })
}

export function refreshToken() {
    return request({
        url: '/admin/auth/refresh',
        method: 'post'
    })
}

export function getVcode(phone) {
    return request({
        url: '/sms/send_code/' + phone,
        method: 'post'
    })
}

export function resetPwd(phone, passwd, vcode) {
    return request({
        url: '/admin/auth/reset',
        method: 'post',
        data: {
            phone,
            passwd,
            vcode
        }
    })
}

export function getCached() {
    return request({
        url: '/service/cached',
        method: 'get'
    })
}

export function setCached(params) {
    return request({
        url: '/service/cached',
        method: 'post',
        data: {
            data: params
        }
    })
}

export function delCached(id) {
    return request({
        url: '/service/cached/' + id,
        method: 'delete'
    })
}
