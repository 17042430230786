/*
 * @Descripttion:用户登录状态模块
 * @Author: congz
 * @Date: 2020-06-04 11:22:40
 * @LastEditors: congz
 * @LastEditTime: 2020-08-06 10:18:08
 */
import { login } from '@/api/admin-auth.js'
import { getToken, setToken, removeToken } from '@/utils/auth.js'

export default {
	state: {
		token: getToken(),
		user: '' // 登录的用户
	},
	getters: {
		getUser(state) {
			return state.user
		}
	},
	mutations: {
		setUser(state, data) {
			state.user = data
		},
		SET_TOKEN: (state, token) => {
			state.token = token
		}
	},
	actions: {
		setUser({ commit }, data) {
			commit('setUser', data)
		},
		login({ commit }, userInfo) {
			const { phone, password } = userInfo
			return new Promise((resolve, reject) => {
				login({ phone: phone.trim(), password: password }).then(res => {
					const { data } = res
					commit('SET_TOKEN', data.token)
					setToken(data.token)
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		}
	}
}
